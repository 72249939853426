import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    component: () => import('../views/Admin.vue'),
    redirect: "/admin/login",
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
      },
      {
        path: 'edit',
        name: 'Edit',
        component: () => import('../views/Edit.vue'),
      }
    ]
  }
]

export default createRouter({
  history: createWebHistory(),
  routes
})