<template>
  <div
    class="grid min-w-min grid-cols-2 gap-2 p-0 md:grid-cols-4 lg:grid-cols-6"
  >
    <div
      class="my-2 inline min-w-min overflow-hidden overflow-ellipsis whitespace-nowrap"
      v-for="item in list"
      :key="item.id"
    >
      <a
        class="min-w-[150px] hover:cursor-pointer"
        :href="item.url"
        target="_blank"
      >
        <img
          class="mx-[5px] inline w-[22px] max-w-none align-middle"
          :src="item.ico"
          alt=""
        />
        <span class="ml-1 align-middle text-[16px] text-[#2d8cf0]">{{
          item.name
        }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Webset",
  props: {
    list: Array,
  },
  data() {
    return {};
  },
};
</script>
