<template>
  <div class="mx-5 lg:mx-14">
    <div class="mx-auto max-w-[900px]">
      <Title v-if="info" :info="info"></Title>
      <div v-for="(item, index) in list">
        <Card class="mt-5">
          <template #title>{{ item.attributes.name }}</template>
          <Webset :list="item.attributes.webs"></Webset>
        </Card>
      </div>
      <div class="my-5 text-center">
        <span id="jinrishici-sentence"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { Card } from "view-ui-plus";
import Webset from "../components/Webset.vue";
import Title from "../components/Title.vue";

import Parse from "parse/dist/parse.min.js";
Parse.initialize("juemuren", "juemuren-javascript-key");
Parse.serverURL = "https://parse-server.juemuren4449.com/parse";
export default {
  name: "Home",
  components: {
    Card,
    Webset,
    Title,
  },
  created() {
    const titleInfo = Parse.Object.extend("juemuren_web_nav_info");
    const titleInfoQurey = new Parse.Query(titleInfo);
    titleInfoQurey
      .first()
      .then((results) => {
        this.info = JSON.parse(JSON.stringify(results));
        document.title = this.info.name;
      })
      .catch((err) => {
        console.log(err);
        // SessionToken 失效
        if (err.code === Parse.Error.INVALID_SESSION_TOKEN) {
          Parse.User.logOut();
          window.location.reload();
        }
      });

    const navType = Parse.Object.extend("juemuren_web_nav_type");
    const navTypeQuery = new Parse.Query(navType);
    navTypeQuery.ascending("order");
    navTypeQuery
      .find()
      .then((results) => {
        this.list = results;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      info: null,
      list: [],
    };
  },
};
</script>
