<template>
  <div class="my-8 text-center md:my-12 lg:my-12">
    <img
      class="inline h-8 w-8 max-w-none align-middle"
      :src="info.logo.url"
      alt="logo"
      @click="toAdmin"
    />
    <span class="align-middle text-[28px]">&nbsp;{{ info.name }}</span>
    <img
      @click="$emit('edit')"
      v-if="isEdit"
      class="inline h-5 w-5 max-w-none align-top hover:cursor-pointer"
      src="../assets/edit.png"
    />
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    info: Object,
    isEdit: Boolean,
  },
  emits: ["edit"],
  methods: {
    toAdmin() {
      window.open(window.location.origin + "/admin");
    },
  },
};
</script>
